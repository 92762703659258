import gql from 'graphql-tag'

export const MUTATION_UPDATE_RETAILER_FLAG_CONFIG = gql`
  mutation updateRetailerFlagConfig($accountId: ID!, $input: UpdateRetailerFlagInput!) {
    updateRetailerFlagConfig(accountId: $accountId, input: $input)
  }
`

export const MUTATION_DELETE_RETAILER_FLAG = gql`
  mutation deleteRetailerFlag($accountId: String!, $id: String!) {
    deleteRetailerFlag(accountId: $accountId, id: $id) {
      message
      success
    }
  }
`

export const QUERY_RETAILER_FLAG_DEFAULT_VALUES = gql`
  query($accountId: String!) {
    retailerFlagDefaultValues(accountId: $accountId) {
      app {
        assets {
          masterIconNavbar
        }
        colors {
          composition {
            components {
              name
              value
            }
            general
          }
          iconBackgroundColor
          primary
          primaryDark
          primaryLight
          primaryText
          primaryTextDark
          primaryTextLight
          secondary
          secondaryDark
          secondaryLight
          secondaryText
          secondaryTextDark
          secondaryTextLight
          splash
        }
      }
      default
    }
  }
`

export const MUTATION_CREATE_RETAILER_FLAG = gql`
  mutation createRetailerFlag($accountId: String!, $data: CreateRetailerFlagInput!) {
    createRetailerFlag(accountId: $accountId, data: $data) {
      message
      success
    }
  }
`

export const MUTATION_UPDATE_RETAILER_FLAG = gql`
  mutation updateRetailerFlag($accountId: String!, $data: UpdateRetailerFlagInput!) {
    updateRetailerFlag(accountId: $accountId, data: $data) {
      message
      success
    }
  }
`

export const MUTATION_SINGLE_UPLOAD_IMAGE_BASE64 = gql`
  mutation SingleUploadImageBase64($filename: String!, $base64: String!) {
    singleUploadImageBase64(filename: $filename, base64: $base64) {
      filename
      mimetype
      url
      __typename
    }
  }
`
